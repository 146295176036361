/* styles.css - Main layout styles */
.main-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.content-area {
  display: flex;
  width: 50%;
  justify-content: center;
}

main {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-container {
    margin-top: 5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #464646;
    max-width: 100%;
    line-height: normal;
}

.project-container a {
  color: #333;
  text-decoration: none;
}
.project-container p {
  color: #333;
  text-decoration: none;
  font-weight: 300;
}
/* WRITING CONTAINER SECTION */
/* WRITING CONTAINER SECTION */
/* WRITING CONTAINER SECTION */

.writing-container {
  flex-direction: column; /* Aligns children in a vertical column */
  align-items: center; /* Centers children horizontally in the container */
  justify-content: center; /* Centers children vertically in the container */
  margin-top: 5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #464646;
  max-width: 700px;
  line-height: normal;
}

.writing-container a {
color: #333;
text-decoration: none;
}
.writing-container p {
color: #333;
text-decoration: none;
font-weight: 300;
}

.writing-container iframe {
  width:"auto"; 
  height:"auto"; 
  /* border-width: 2px;
  border-color: #007bff; */
  margin-left: 25%;
  margin-right: 25%;
}

/* Fixed image at the bottom-right of the viewport */
.tree-image {
  width: 100px;
  height: auto;
  position: fixed;
  right: 200px;
  bottom: 10px;
  z-index: 10;
}

/* Sidebar styles */
aside {
  margin-top: 5rem;
  font-size: 1.8rem;
  font-weight: bold;
  color: #464646;
  line-height: 1.4;
  max-width: 100%;
}

aside h1, aside h2, aside h3 {
  color: #333;
  margin-top: 1rem;
}

aside a {
  color: #007bff;
  text-decoration: none;
}

aside a:hover {
  text-decoration: underline;
}

aside p {
  margin-bottom: 1rem;
}

/* Navigation bar styling */
nav {
  width: 100%;
  margin-left: 30%;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
}

/* Home link styles */
nav .home-link {
  font-size: 1.5em;
  text-decoration: none;
  border-left: 2px solid rgb(0, 0, 255);
  border-right: 2px solid rgb(0, 0, 255);
  color: #333;
  font-weight: bold;
}

/* Navigation links container */
.nav-links {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-weight: bold;
}

/* Navigation link styles */
nav a {
  border-left: 1px solid rgb(0, 0, 255);
  border-right: 1px solid rgb(0, 0, 255);
  padding: 0 20px;
}

/* Link state styles */
nav a:link, 
nav a:visited, 
nav a:hover, 
nav a:active, 
nav a:focus {
  color: black;
  text-decoration: none;
}

.nav-links a:first-child {
border-left: none;
}

.nav-links a:last-child {
  border-right: none;
}

.nav-links a:hover,
.nav-links a.active {
  background-color: transparent;
}

/* Highlights container styles */
.highlights-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

/* Individual highlight styles */
.highlight-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.highlight-box h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.highlight-box p {
  color: #666;
  margin-bottom: 1rem;
}

.highlight-box a {
  text-decoration: none;
  color: #007bff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

